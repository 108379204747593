import React, { ChangeEvent, useEffect } from "react";
import "./ListItemsDropdownPreference.scss";
import { ItemPreference, FormOption, FormOptionLocales } from "../../../../repositories/interfaces";

import ResponseOptions from "../ResponseOptions";
import { useTranslation } from "react-i18next";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import { RhimButton, RhimModal } from "rhim-ui";
import { useStoreState, useStoreActions } from "../../../../store/hooks";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import { FormRule } from "../../../../feature/rules/interface/rulesInterface";
import { LanguageType } from "../../../../feature/languages/enum/LanguageTypes";
import useModalVisibility from "../../../../feature/modal/hooks/useModalVisibility";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";

interface ListItemsDropdownPreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  selectedField: CatalogItem;
}

const ListItemsDropdownPreference = (props: ListItemsDropdownPreferenceProps) => {
  const { t } = useTranslation();
  const { preference, onChangeFunction, selectedField } = props;
  const rules = useStoreState((state) => state.rules.rules);
  const updateRule = useStoreActions((action) => action.rules.updateRule);
  const currentLanguage = useStoreState((state) => state.languages.selectedLanguage);
  const defaultLanguage = useStoreState((state) => state.languages.formDefaultLanguage);
  const items = useStoreState((state) => state.fields.items);

  const { modalProperties, setModalProperties } = useModalVisibility({
    visible: false
  });

  const getContent = (preference: ItemPreference) => {
    let optionsArray = [];

    if (preference.value && preference.value !== "null") {
      optionsArray = JSON.parse(preference.value);
    }
    const options = optionsArray.map(
      (option: FormOption) =>
        option.formOptionLocales.find(
          (locale: FormOptionLocales) => locale.cultureId === defaultLanguage?.id
        )?.option
    );
    return options.join("\n");
  };

  const addResponse = (items: ItemPreference) => {
    const optionsArray: any[] = items.value ? JSON.parse(items.value) : [];

    let newOption = {
      formFieldId: selectedField.id,
      fieldId: selectedField.id,
      option: "",
      index: optionsArray.length,
      formOptionLocales: [
        {
          cultureId: currentLanguage?.id ?? 2,
          formOptionId: 0,
          option: ""
        }
      ]
    };
    optionsArray.push(newOption);
    return optionsArray;
  };

  const verifyInvalidOptions = (scoreFlag: boolean, options: any) => {
    const optionsArray = options.value ? JSON.parse(options.value) : [];
    let invalid = false;
    if (scoreFlag) {
      optionsArray.forEach((a: any) => {
        if (!a.isNA && !a.weight && a.weight !== 0) {
          invalid = true;
        }
      });
    }
    return invalid;
  };

  const updateOptionRules = (newPreferences: any) => {
    rules.forEach((rule: FormRule) => {
      if (rule.target === selectedField.fieldId) {
        updateTargetOptions(rule, newPreferences);
      } else if (rule.agent === selectedField.fieldId) {
        updateRuleResponse(rule, newPreferences);
      }
    });
  };

  const updateTargetOptions = (rule: FormRule, newPreferences: any) => {
    if (rule.targetOptions) {
      const newPreferencesMap = new Map(newPreferences.map((op: any) => [op.option, op]));
      const newTargetOptions: any[] = rule.targetOptions
        .map((top: any) => newPreferencesMap.get(top.label))
        .filter(Boolean)
        .map((op: any) => ({
          label: op.option,
          value: op.index
        }));

      updateRule({
        ...rule,
        targetOptions: newTargetOptions
      });
    }
  };

  const updateRuleResponse = (rule: FormRule, newPreferences: any) => {
    const fieldPreferences = items[selectedField.fieldListUuid][
      selectedField.index
    ].preferences.find((p: any) => p.key === "ListItemsDropdown");
    const currentRuleResponse =
      fieldPreferences && rule.response !== ""
        ? JSON.parse(fieldPreferences.value)[rule.response].option
        : "";
    let newRuleResponse = "";
    if (currentRuleResponse !== "") {
      newPreferences.forEach((op: any) => {
        if (op.option === currentRuleResponse && op.option !== "") {
          newRuleResponse = op.index;
        }
      });
    }
    updateRule({
      ...rule,
      response: newRuleResponse
    });
  };

  const content = getContent(preference);

  const updateOptionPreferenceListInBuilderPage = (newOptions: string) => {
    let options = newOptions.split("\n");
    let optionsObject: any[] = preference.value ? JSON.parse(preference.value) : [];
    let index = 0;

    for (index; index < options.length; index++) {
      if (!optionsObject[index]) {
        optionsObject.push({
          index: index,
          isNA: null,
          option: options[index],
          weight: null,
          formOptionLocales: [
            {
              cultureId: defaultLanguage?.id,
              option: options[index]
            }
          ]
        });
        continue;
      }
      let localeToUpdate = optionsObject[index]?.formOptionLocales.find(
        (locale: any) => locale.cultureId === defaultLanguage?.id
      );
      if (localeToUpdate) {
        localeToUpdate.option = options[index];
        optionsObject[index].option = options[index];
      } else {
        optionsObject[index]?.formOptionLocales.push({
          fieldId: selectedField.id,
          formFieldId: selectedField.id,
          formOptionLocales: [
            {
              cultureId: currentLanguage?.id ?? LanguageType.English_Id,
              option: options[index]
            }
          ],
          index: index,
          isNA: null,
          option: options[index]
        });
      }
    }
    if (optionsObject.length > options.length) {
      let numItemsToRemove = optionsObject.length - options.length;
      optionsObject.splice(index, numItemsToRemove);
    }
    updateOptionRules(optionsObject);
    preference.value = optionsObject;
    onChangeFunction(preference);
  };

  const renderModalContent = () => {
    const hasInvalidOption = verifyInvalidOptions(selectedField.score, preference);
    return (
      <div>
        <div className="messageModal">
          {hasInvalidOption && <span className="warning">{t("messages.required_fields")}</span>}
          <ResponseOptions onChangeFunction={onChangeFunction} preference={preference} />
        </div>
        <div id="modal-footer">
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}>
            <Grid item>
              <RhimButton
                title={t("common.add").toUpperCase()}
                variant="outlined"
                icon={<Icon>add</Icon>}
                onClick={() => {
                  preference.value = addResponse(preference);
                  onChangeFunction(preference);
                }}
              />
            </Grid>
            <Grid item>
              <RhimButton
                title={t("common.save").toUpperCase()}
                variant="contained"
                onClick={() => {
                  setModalProperties({ visible: false });
                }}
                disabled={hasInvalidOption}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <section className="list-items-preference-dropdown">
      <TitleColumn text={t("preferences.options")} />
      {selectedField.score && (
        <span className="addOptions-button button">
          <RhimButton
            variant="outlined"
            title={t("common.add").toUpperCase()}
            iconSide="right"
            icon={<Icon>arrow_forward</Icon>}
            onClick={async () => {
              setModalProperties({ visible: true, title: "form.title" });
            }}
          />
        </span>
      )}
      {!selectedField.score && (
        <textarea
          value={content}
          className="content"
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            updateOptionPreferenceListInBuilderPage(event.target.value)
          }
          placeholder={t("preferences.options_placeholder")}
        />
      )}

      <RhimModal
        modalProps={{ id: "dropdown-options-modal" }}
        title={t("common.add")}
        content={renderModalContent()}
        showModal={modalProperties.visible}
        onClose={() => {
          setModalProperties({ visible: false });
        }}
        isMobile={false}
        modalSizeHeight="auto"
        modalSizeWidth="50%"
      />
    </section>
  );
};

export default ListItemsDropdownPreference;
