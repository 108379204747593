import React from "react";
import "./ResponseOptions.scss";
import { FormOptionLocales, ItemPreference, FormOption } from "../../../../repositories/interfaces";
import { TitleColumn, Toggle, InputOnlyNumber } from "../../atoms";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { RhimButton } from "rhim-ui";
import { Icon } from "@material-ui/core";
import { useStoreState } from "../../../../store/hooks";
import { LanguageType } from "../../../../feature/languages/enum/LanguageTypes";

interface ResponseOptionsProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
}

const ResponseOptions = (props: ResponseOptionsProps) => {
  const { t } = useTranslation();
  const { onChangeFunction, preference } = props;
  const items = preference.value ? JSON.parse(preference.value) : [];
  const gridResponse = preference.key === "ListItemsRadio" ? 10 : 8;
  const currentLanguage = useStoreState((state) => state.languages.selectedLanguage);
  const selectedField = useStoreState((state) => state.fields.selectedField);

  const updatePreferencesValues = (item: FormOption, index: number, key: string) => {
    const optionsArray = preference.value ? JSON.parse(preference.value) : [];
    optionsArray[index] = item;
    return key === "ListItemsRadio" ? JSON.stringify(optionsArray) : optionsArray;
  };

  const deletePreferencesValues = (index: number) => {
    const optionsArray = preference.value ? JSON.parse(preference.value) : [];
    optionsArray.splice(index, 1);
    return optionsArray;
  };

  const verifyNotApplyOption = (options: FormOption[]) => {
    return options?.some((item: FormOption) => item.isNA);
  };

  const addNotApplyOption = (options: FormOption[]) => {
    options.unshift({
      option: "N/A",
      weight: undefined,
      isNA: true,
      index: 0,
      formOptionLocales: [
        {
          cultureId: currentLanguage?.id ?? LanguageType.English_Id,
          option: "N/A",
          formOptionId: 0
        }
      ],
      fieldId: selectedField?.id ?? 0,
      formFieldId: selectedField?.id ?? 0
    });
    return items;
  };

  const removeNotApplyOption = (options: FormOption[]) => {
    options.splice(0, 1);
    return items;
  };

  const changeOption = (formOption: FormOption, newOption: string, index: number) => {
    formOption.option = newOption;
    const newOptioId = 0;
    if (!formOption.formOptionLocales) {
      formOption.formOptionLocales = [];
      formOption.formOptionLocales.push({
        cultureId: currentLanguage?.id ?? LanguageType.English_Id,
        formOptionId: formOption.id ?? newOptioId,
        option: newOption
      });
    } else {
      let localeToUpdate = formOption.formOptionLocales.find(
        (locale: FormOptionLocales) => locale.cultureId === currentLanguage?.id
      );
      if (localeToUpdate) localeToUpdate.option = newOption;
    }
    preference.value = updatePreferencesValues(formOption, index, preference.key);

    onChangeFunction(preference);
  };

  return (
    <section id="response">
      <Grid container direction="row" className="tableHeader">
        <Grid item lg={gridResponse} md={gridResponse} xs={gridResponse} sm={gridResponse}>
          <span>{t("add_options.response")}</span>
        </Grid>
        <Grid item lg={2} md={2} xs={2} sm={2}>
          <span>{t("add_options.weight")}</span>
        </Grid>
        {preference.key !== "ListItemsRadio" && <Grid item lg={2} md={2} xs={2} sm={2}></Grid>}
      </Grid>

      {items?.map(
        (item: FormOption, index: number) =>
          !item.isNA && (
            <Grid container direction="row" className="tableRow" key={index}>
              <Grid item lg={gridResponse} md={gridResponse} xs={gridResponse} sm={gridResponse}>
                <input
                  maxLength={200}
                  type="text"
                  className={`inputResponse${index}`}
                  placeholder={t("add_options.option")}
                  disabled={item.isNA}
                  value={item.option}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeOption(item, event.target.value, index)
                  }
                />
              </Grid>

              <Grid item lg={2} md={2} xs={2} sm={2}>
                <InputOnlyNumber
                  customStyle={
                    !item.weight && item.weight !== 0 ? { borderColor: "red" } : undefined
                  }
                  className={`inputWeight${index}`}
                  value={item.weight}
                  placeholder={t("add_options.weight")}
                  disabled={item.isNA}
                  onChange={(value?: number) => {
                    item.weight = value;
                    preference.value = updatePreferencesValues(item, index, preference.key);
                    onChangeFunction(preference);
                  }}
                />
              </Grid>

              {preference.key !== "ListItemsRadio" && !item.isNA && (
                <Grid item lg={2} md={2} xs={2} sm={2} className="delete">
                  <RhimButton
                    className={"delete-option"}
                    onClick={() => {
                      preference.value = deletePreferencesValues(index);
                      onChangeFunction(preference);
                    }}
                    title={t("common.delete").toUpperCase()}
                    icon={<Icon>delete</Icon>}
                  />
                </Grid>
              )}
            </Grid>
          )
      )}
      {preference.key !== "ListItemsRadio" && (
        <Grid container direction="row" className="tableFoot" spacing={1}>
          <Grid item>
            <TitleColumn text={t("add_options.add_na")} />
          </Grid>
          <Grid item>
            <Toggle
              isSelected={verifyNotApplyOption(items)}
              changeToggleValue={() => {
                if (verifyNotApplyOption(items)) {
                  preference.value = removeNotApplyOption(items);
                } else {
                  preference.value = addNotApplyOption(items);
                }
                onChangeFunction(preference);
              }}
            />
          </Grid>
        </Grid>
      )}
    </section>
  );
};

export default ResponseOptions;
