import { deAT, enUS, esES, frFR, ptBR, trTR, zhCHS } from "../i18n";

import detector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import storage from "../helpers/storage";

const resources = {
  "en-US": {
    translation: enUS
  },
  "pt-BR": {
    translation: ptBR
  },
  "es-ES": {
    translation: esES
  },
  "fr-FR": {
    translation: frFR
  },
  "de-AT": {
    translation: deAT
  },
  "zh-CHS": {
    translation: zhCHS
  },
  "tr-TR": {
    translation: trTR
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: storage.getLanguageIsoCode() || "en-US",
    fallbackLng: "en-US",
    keySeparator: "#",
    nsSeparator: "§",  
    interpolation: {
      escapeValue: false
    }
  });

i18n.on("languageChanged", (lng) => {
  storage.setLanguageIsoCode(lng);
});

export default i18n;
