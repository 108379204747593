export default {
  "common.cancel": "Abbrechen",
  "common.delete": "Löschen",
  "common.duplicate": "Duplizieren",
  "common.title": "Titel",
  "common.upload": "Hochladen",
  "common.create": "Erstellen",
  "common.file": "Datei",
  "common.section": "Abschnitt",
  "common.add": "hinzufügen",
  "common.continue": "Weiter",
  "common.confirm": "Bestätigen Sie",
  "common.save": "Speichern",
  "common.saved": "Gespeichert",
  "common.yes": "Ja",
  "common.no": "Nein",
  "common.skip": "Überspringen",
  "common.ok": "Ok",
  "common.success": "Erfolg",
  "common.sign_out": "Ausloggen",
  "common.answer": "Antwort",
  "common.rule": "Regel",

  "message.createAnswer.success": "Antwort erfolgreich gespeichert!",
  "message.createAnswer.error.answer": "Fehler beim Erstellen der Antwort",
  "message.createAnswer.error.image": "Fehler beim Erstellen der Bilder",
  "message.createAnswer.error.image.type": "Ungültiger Bildtyp",
  "message.getAnswers.error.invalidInput":
    "Bitte geben Sie einen maximalen Zeitraum von {{maxDays}} Tagen ein, um die Antworten zu filtern.",
  "message.getAnswers.error.answer": "Fehler beim AnswerData Parse",
  "message.getAnswers.error.image": "Fehler beim Bilder Parse",
  "message.formValidation.missingTitle": "Bitte füllen Sie das Feld Titel aus.",
  "message.formValidation.missingEmail": "Bitte füllen Sie das E-Mail-Feld aus.",
  "message.formValidation.missingSectionTitle": "Bitte füllen Sie den Titel aller Abschnitte aus.",
  "message.formValidation.missingFieldTitle": "Bitte füllen Sie alle Feldtitel aus.",
  "message.formValidation.missingFieldType": "Bitte wählen Sie Typ für alle Felder.",
  "message.formValidation.missingFieldId": "Geben Sie eine ID für alle Felder ein.",
  "message.formValidation.missingEquipmentMrk":
    "Bitte wählen Sie im Gerätefeld eine Geräte-MRK aus.",
  "message.formValidation.missingSection":
    "Ein Formular benötigt mindestens einen Abschnitt, um ordnungsgemäß zu funktionieren.",
  "message.formValidation.multipleCustomerField":
    "Das Formular kann nur ein SAR-Kundenfeld enthalten.",
  "message.formValidation.missingCustomerField":
    "Um ein Formular mit SAR-Ausrüstung zu erstellen, setzen Sie bitte vorher ein Feld vom Typ SAR-Kunde.",
  "message.formValidation.missingEquipmentField":
    "Um ein Formular mit SAR-Kampagne/SAR-Endgültiger Grund zu erstellen, setzen Sie bitte vorher ein Feld vom Typ SAR-Ausrüstung.",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    "SAR-Kunde muss vor SAR-Equipment eingegeben werden",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    "Der SAR-Kunde muss vor einer SAR-Kampagne eingegeben werden",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    "Der SAR-Kunde muss vor einem SAR-Endgrund eingegeben werden",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "SAR-Ausrüstung muss vor SAR-Kampagne eingegeben werden",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "SAR-Ausrüstung muss vor SAR-Endgrund eingegeben werden",
  "message.formValidation.wrongCampaignFieldPosition":
    "Die SAR-Kampagne muss vor dem SAR-Abreisegrund eingegeben werden",
  "message.formValidation.wrongEquipmentFieldPosition":
    "SAR-Ausrüstung muss vor der SAR-Kampagne vorhanden sein.",
  "message.formValidation.failureValidatingRules": "Fehler beim Überprüfen der Formularregeln.",
  "message.formValidation.missingTargetOptionsInRules": "Fragenoptionen fehlen in Formularregeln.",
  "message.createForm.success": "Formular erfolgreich gespeichert!",
  "message.createForm.error": "Fehler beim Speichern des Formulars. Versuchen Sie es nochmal.",
  "message.updateForm.success": "Formular erfolgreich gespeichert.",
  "message.updateForm.error": "Fehler beim Speichern des Formulars",
  "message.toggleFormEnable.success.enable": "Formular erfolgreich aktiviert!",
  "message.toggleFormEnable.success.disable": "Form erfolgreich deaktiviert!",
  "message.toggleFormEnable.error": "Fehler beim Aktivieren / Deaktivieren des Formulars.",
  "message.hasAnswers": "Form hat Antworten.",
  "message.hasNoAnswers": "Form hat keine Antworten.",
  "message.hasAnswers.error": "Fehler beim Überprüfen, ob das Formular Antworten enthält",
  "message.deleteForm.success": "Formular erfolgreich gelöscht.",
  "message.deleteForm.error": "Fehler beim Löschen des Formulars.",
  "message.duplicate.modal.title": "Unter welchem Namen möchten Sie Ihre Kopie speichern?",
  "message.duplicateForm.success": "Formular erfolgreich dupliziert.",
  "message.duplicateForm.error": "Fehler beim Duplizieren des Formulars",
  "message.getMrk.error": "Fehler beim Abrufen von MRK",
  "message.getCulture.error": "Nicht unterstützte Sprache",

  "messages.oops": "Hoppla",
  "messages.link_copied": "Link kopiert",
  "messages.confirm_delete":
    "Sind Sie sicher, dass Sie <Formularname> löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  "messages.comfirm_delete_answers":
    "Dieses Formular enthält bereits Antworten. Wenn Sie fortfahren, werden alle Antworten gelöscht und diese Aktion kann nicht rückgängig gemacht werden. Wollen Sie fortfahren?",
  "messages.confirm_title_duplicate": "Es wird mit dem untenstehenden Namen an den Anfang der Liste gesendet:",
  "messages.required_fields": "Bitte füllen Sie alle erforderlichen Felder aus.",
  "messages.error_support":
    "Bei der Ausführung dieser Aktion ist ein Problem aufgetreten. Wenn der Fehler weiterhin besteht, wenden Sie sich bitte an unseren Support.",
  "messages.error_field_id": "Bitte kreuzen Sie Feld Id an!",
  "messages.error_field_weight": "Bitte prüfen Sie die Gewichtsfelder!",
  "messages.error_all_field_weight": "Bitte füllen Sie alle Gewichtsfelder aus",
  "messages.error_fields_repeated_id":
    "Das Feld {{fieldIdA}} ID des Abschnitts {{sectionIdA}} ist die gleiche wie das Feld {{fieldIdB}} ID des Abschnitts {{sectionIdB}}.",
  "messages.error_section_repeated_id": "Es gibt Abschnitte mit der gleichen Id!",
  "messages.error_section_name": "Bitte, überprüfen Sie den Namen der Sektion!",
  "messages.error_section_id": "Bitte, überprüfen Sie den Id der Sektion!",
  "messages.error_section_repeated_name": "Es gibt gleichnamige Sektionen!",
  "messages.error_mrk": "Kein MRK gefunden",
  "messages.construction":
    "Dieser Abschnitt ist im Aufbau, um Ihre Erfahrungen weiter zu verbessern",
  "messages.languages_note":
    "Wenn Sie ein leeres Feld belassen, wird es als Standardsprache für den Benutzer angezeigt.",
  "messages.users_not_found": "Nicht gefundene Benutzer",
  "messages.confirm_rules": "Möchten Sie Regeln für dieses Formular festlegen?",
  "messages.empty_rule": "Wenn Sie eine bedingte Regel haben, kann diese hier erstellt werden",
  "messages.empty_section": "Wählen Sie ein Feld aus, um es innerhalb des Abschnitts hinzuzufügen",
  "messages.saved":
    "Ihr Formular wurde erfolgreich gespeichert! Sie können es jetzt auf Meine Formulare überprüfen.",
  "messages.sign_out": "Möchten Sie die Verbindung zum System trennen?",
  "messages.cannot_change_form_status":
    "Sie können dieses Formular nicht aktivieren oder deaktivieren, da Sie Miteigentümer sind.",
  "pages.my_forms": "Meine Formulare",
  "pages.new_form": "Neues Formular",
  "pages.feedback": "Rückmeldung",
  "pages.builder": "Erbauer",
  "pages.rules": "Regeln",
  "pages.languages": "Sprache",
  "pages.users": "Benutzer",
  "pages.usersAndCoOwners": "Nutzer und Miteigentümer",
  "my_forms.id": "ID",
  "my_forms.form_code": "Formular-Code",
  "my_forms.creation_date": "Datum der erstellung",
  "my_forms.updated_date": "Letzte änderungen",
  "my_forms.enable": "Aktivieren Sie",
  "my_forms.owner": "Eigentümer",
  "my_forms.actions": "Aktionen",
  "actions.form": "Formular bearbeiten",
  "actions.rules": "Regeln bearbeiten",
  "actions.users": "Benutzer bearbeiten",
  "actions.languages": "Sprache bearbeiten",
  "actions.power_bi": "PowerBI Link",
  "actions.export": "Export-Formular",
  "actions.delete": "Formular löschen",
  "actions.duplicate": "Formular duplizieren",
  "new_form.import": "Neues Formular importieren",
  "new_form.creation_language": "Schöpfungssprache",
  "languages.portuguese": "Portugiesisch",
  "languages.english": "Englisch",
  "languages.spanish": "Spanisch",
  "languages.german": "Deutsch",
  "languages.mandarin": "Mandarin",
  "languages.french": "Französisch",
  "languages.turkish": "Türkisch",
  "fields.text": "Kurzer Text",
  "fields.textarea": "Langtext",
  "fields.number": "Numerisch",
  "fields.datetime": "Datum und Stunde",
  "fields.photo": "Foto",
  "fields.dropdown": "Dropdown-Menü",
  "fields.check_list": "Checklist",
  "fields.single_option": "Einzelne Option",
  "fields.customer": "Kunde",
  "fields.equipment": "Ausrüstung",
  "fields.campaign": "Kampagne",
  "fields.end_reason": "Endgültiger Grund",
  "fields.qr_code": "Qr/Bar-Code",
  "fields.signature_qr": "Signatur-QR-Code",
  "fields.boolean": "Einzelne Option",
  "fields.dropdown-campaign": "Kampagne",
  "fields.dropdown-campaign-end-reason": "Endgültiger Grund",
  "fields.dropdown-customer": "Kunde",
  "fields.dropdown-equipment": "Ausrüstung",
  "fields.pictureFile": "Foto",
  "fields.qrbarcode": "Qr/Bar-Code",
  "fields.signature-qrcode": "Signatur-QR-Code",
  "constructor.fields": "Felder",
  "constructor.filter": "Filter",
  "constructor.by.equipment": "Nach Ausrüstung filtern",
  "constructor.by.customer": "Nach Kunden filtern",
  "constructor.basic": "Grundlegend",
  "constructor.rhim": "RHIM-Integration",
  "constructor.section_title": "Abschnitt Titel",
  "constructor.unsaves_changes": "Nicht gespeicherte Änderungen",
  "constructor.mrk": "MRK der Ausrüstung",
  "constructor.preferences": "Einstellungen",
  "preferences.placeholder": "Wählen Sie ein Feld aus, um seine Einstellungen zu bearbeiten",
  "preferences.required": "Erfordelich",
  "preferences.score": "Partitur",
  "preferences.options": "Optionen",
  "preferences.automatic": "Automatisch",
  "preferences.automatic_id": "Automatisch Id",
  "preferences.field_id": "Feld Id",
  "preferences.field_weight": "Feld Gewicht",
  "preferences.section_weight": "Abschnitt Gewicht",
  "preferences.section_id": "Abschnitt Id",
  "preferences.options_placeholder":
    "Geben Sie Elemente ein, indem Sie sie mit der Eingabetaste trennen",
    "preferences.checklistValue": "Maximal aktivierte Optionen",
  "preferences.mrk_placeholder": "Wählen Sie eine MRK",
  "preferences.max": "Maximum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Usar como descrição de formulário",
  "preferences.typeQrOrBarCode": "Typ",
  "preferences.IntegerOrFloat": "Typ",
  "preferences.decimalPlacesMin" : "Minimale Dezimalstellen",
  "preferences.DecimalPlaces": "DecimalPlaces",
  "preferences.option_integer": "Ganzzahl",
  "preferences.option_decimal": "Dezimal",
  "preferences.error_empty_qrcode": "Choose at least one option",

  "add_options.option": "Option",
  "add_options.weight": "Gewicht",
  "add_options.response": "Antwort",
  "add_options.add_na": "N/A hinzufügen",
  "construction.go_back": "Zurückgehen",
  "languages.notes": "Anmerkungen",
  "users.email": "E-Mail-Benutzer",
  "coOwners.email": "Miteigentümer E-Mail",
  "users.multiple_association":
    "Mehrfache Assoziation (füllen Sie sie mit einer Mailingliste mit Semikolon-Trennzeichen)",
  "users.finish": "Formular beenden",
  "globalization.title": "Schreiben Sie Ihren Formulartitel auf",
  "globalization.section": "Schreiben Sie Ihren Abschnittstitel auf",
  "globalization.field": "Schreiben Sie Ihren Feldnamen auf",
  "globalization.field.description": "Geben Sie die Beschreibung Ihres Feldes auf",
  "globalization.option": "Schreiben Sie Ihren Optionswert auf",

  "rules.if": "Wenn",
  "rules.status": "Status",
  "rules.response": "Antwort",
  "rules.action": "Aktion",
  "rules.question": "Frage",
  "rules.options": "Optionen",

  "option.is": "Ist",
  "option.isn't": "Ist nicht",
  "option.hide.section": "Abschnitt ausblenden",
  "option.hide.field": "Feld ausblenden",
  "option.show.section": "Abschnitt anzeigen",
  "option.show.field": "Feld anzeigen",
  "option.filter": "Filter",
  "option.required": "Erforderlich",

  "pagination.view": "Affichage {{from}}-{{to}} de {{count}}",
  "pagination.rows_per_page": "Lignes par page:",
  "messages.error_form_access_denied":
    "Sie haben keinen Zugriff auf dieses Formular. Bitte suchen Sie nach einem Formular, bei dem Sie Autor oder Co-Autor sind.",
  "messages.page_not_found": "Seite nicht gefunden",
  "messages.text_page_not_found":
    "Entschuldigung, die Seite, auf die Sie zugreifen möchten, existiert nicht.",
  "messages.go_back_my_forms": "Zurück zu Meine Formulare",
  "messages.access_denied": "Zugriff abgelehnt",
  "messages.text_access_denied":
    "Sie haben keinen Zugriff auf dieses Formular. Bitte suchen Sie nach einem Formular, bei dem Sie Autor oder Co-Autor sind.",
  "messages.form_not_found": "Formular nicht gefunden",
  "messages.text_form_not_found":
    "Entschuldigung, das Formular, auf das Sie zugreifen möchten, existiert nicht. Bitte suchen Sie nach einem anderen Formular oder erstellen Sie ein neues.",
  "message.formValidation.multipleEquipmentInSection":
    "Das Formular kann nur ein SAR-Ausrüstungsfeld pro Abschnitt enthalten",
  "preferences.description": "Beschreibung",
  "messages.error_field_no_options": "Bitte überprüfen Sie die Feldoptionen!",
  "messages.error_single_option_no_options":
    "Sie können ein einzelnes Optionsfeld nicht ohne Optionen speichern.",
  "messages.fieldsInformDescriptionPreference":
    "You have already selected {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fields",
  "messages.max_filled_as_form_description": "To select this field, please unselect other field",
  "messages.tooltip_decimal_minimum_alert":
    "Aufgrund der 2 Dezimalstellen beträgt das Mindestzeichenlimit für Dezimalfelder 3.",
  "message.form_last_update": "Letzte aktualisierung",
  "message.form_last_update_by": "Durch"
};
