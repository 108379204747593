export default {
  "common.cancel": "Cancel",
  "common.delete": "Delete",
  "common.duplicate": "Duplicate",
  "common.title": "Title",
  "common.upload": "Upload",
  "common.create": "Create",
  "common.file": "File",
  "common.section": "Section",
  "common.add": "Add",
  "common.continue": "Continue",
  "common.confirm": "Confirm",
  "common.save": "Save",
  "common.saved": "Saved",
  "common.yes": "Yes",
  "common.no": "No",
  "common.skip": "Skip",
  "common.ok": "Ok",
  "common.success": "Success",
  "common.sign_out": "Sign Out",
  "common.answer": "Answer",
  "common.rule": "Rule",

  "message.createAnswer.success": "Response saved successfully!",
  "message.createAnswer.error.answer": "Fail on creating answer",
  "message.createAnswer.error.image": "Fail on creating image",
  "message.createAnswer.error.image.type": "Invalid image type",
  "message.getAnswers.error.invalidInput":
    "Please enter a maximum period of {{maxDays}} days to filter the responses.",
  "message.getAnswers.error.answer": "Fail on parsing AnswerData",
  "message.getAnswers.error.image": "Fail on parsing Pictures",
  "message.formValidation.missingTitle": "Please fill the Title field",
  "message.formValidation.missingEmail": "Please fill the email field",
  "message.formValidation.missingSectionTitle": "Please fill the title of all sections",
  "message.formValidation.missingFieldTitle": "Please fill all fields titles",
  "message.formValidation.missingFieldType": "Please select type to all fields",
  "message.formValidation.missingFieldId": "Please put an Id for all fields.",
  "message.formValidation.missingEquipmentMrk": "Please select a equipment MRK on equipment field",
  "message.formValidation.missingSection": "A form needs at least one section to work properly",
  "message.formValidation.multipleCustomerField": "The form can only have one SAR Customer field",
  "message.formValidation.multipleEquipmentInSection":
    "The form can only have one  SAR Equipment field per section",
  "message.formValidation.missingCustomerField":
    "To create a form with SAR Equipment, please put a field of type SAR Customer before",
  "message.formValidation.missingEquipmentField":
    "To create a form with SAR Campaign/Ending Reason, please put a field of type SAR Equipment before",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    "SAR Customer needs to be entered before SAR Equipment",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    "SAR Customer needs to be entered before a SAR Campaign",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    "SAR Customer needs to be entered before a SAR End Reason",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "SAR Equipment needs to be entered before SAR Campaign",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "SAR Equipment needs to be entered before SAR End Reason",
  "message.formValidation.wrongEquipmentFieldPosition":
    "SAR Equipment needs to be placed before SAR Campaign",
  "message.formValidation.failureValidatingRules": "Failure validating form rules.",
  "message.formValidation.missingTargetOptionsInRules":
    "Question options are missing in form rules.",
  "message.createForm.success": "Form saved with success!",
  "message.createForm.error": "Fail on save form. Try again.",
  "message.updateForm.success": "Form saved with success.",
  "message.updateForm.error": "Error on save form",
  "message.toggleFormEnable.success.enable": "Form enabled with success!",
  "message.toggleFormEnable.success.disable": "Form disabled with success!",
  "message.toggleFormEnable.error": "Error on enable/disable form",
  "message.hasAnswers": "Form has answers.",
  "message.hasNoAnswers": "Form has no answers.",
  "message.hasAnswers.error": "Error on checking if form has answers",
  "message.deleteForm.success": "Form deleted with success.",
  "message.deleteForm.error": "Error deleting form.",
  "message.duplicate.modal.title": "With which name would you like your copy to be saved?",
  "message.duplicateForm.success": "Form successfully duplicated.",
  "message.duplicateForm.error": "Error duplicating form.",
  "message.getMrk.error": "Error on get MRK",
  "message.getCulture.error": "Unsupported language",

  "messages.oops": "Oops",
  "messages.link_copied": "Link copied",
  "messages.confirm_delete":
    "Are you sure you want to delete {{formTitle}}? This action can not be undone.",
  "messages.confirm_delete_answers":
    "This form has answers already. By proceeding, all answers will be deleted and this action can not be undone. Do you want to proceed?",
  "messages.confirm_title_duplicate": "It will be sent to the top of the list with the name below:",
  "messages.required_fields": "Please fill all the required fields",
  "messages.error_support":
    "A problem occurred while performing this action. If the error persists, please contact our support.",
  "messages.error_field_id": "Please, check field Id!",
  "messages.error_field_no_options": "Please, check fields options!",
  "messages.error_field_preference_no_options": "Please, check the field's preferences",
  "messages.error_qrbarcode_field_preference_no_options":
    "You can't save a QR/Barcode field without selecting at least one type",
  "messages.error_field_dropdown.title": "You can't save a dropdown with no options.",
  "messages.error_field_weight": "Please, check weight fields!",
  "messages.error_all_field_weight": "Please, complete all weight fields!",
  "messages.error_fields_repeated_id":
    "The field {{fieldIdA}} ID of section {{sectionIdA}} is the same as the field {{fieldIdB}} of section {{sectionIdB}}.",
  "messages.error_section_repeated_id": "There are sections with the same Id!",
  "messages.error_section_name": "Please, check section name!",
  "messages.error_section_id": "Please, check section id!",
  "messages.error_section_repeated_name": "There are sections with the same name!",
  "messages.error_mrk": "No MRK found.",
  "messages.error_multiphotos_preferences": "Please, check multiphotos preferences!",
  "messages.error_invalid_preference": "Invalid preference values",
  "messages.construction": "This section is under construction to further improve your experience",
  "messages.languages_note":
    "If you leave an empty field, it will be shown as the default language for the user.",
  "messages.users_not_found": "No users found",
  "messages.confirm_rules": "Do you want to stablish Rules for this form?",
  "messages.empty_rule": "If you have a conditional rule it can be created in here",
  "messages.empty_section": "Select a field to add it inside the section",
  "messages.saved": "Your form has been successfully saved! You can check it now on My Forms.",
  "messages.sign_out": "Do you want to disconnect from the system?",
  "messages.cannot_change_form_status":
    "You cannot enable or disable this form, since you are a co-owner.",
  "pages.my_forms": "My Forms",
  "pages.new_form": "New Form",
  "pages.feedback": "Feedback",
  "pages.builder": "Builder",
  "pages.rules": "Rules",
  "pages.languages": "Languages",
  "pages.users": "Users",
  "pages.usersAndCoOwners": "Users and co-owners",
  "my_forms.id": "ID",
  "my_forms.form_code": "Form Code",
  "my_forms.creation_date": "Creation date",
  "my_forms.updated_date": "Last changes",
  "my_forms.enable": "Enable",
  "my_forms.owner": "Owner",
  "my_forms.actions": "Actions",
  "actions.form": "Edit Form",
  "actions.rules": "Edit Rules",
  "actions.users": "Edit Users",
  "actions.languages": "Edit Languages",
  "actions.power_bi": "Power BI link",
  "actions.export": "Export Form",
  "actions.delete": "Delete Form",
  "actions.duplicate": "Duplicate Form",
  "new_form.import": "Import new Form",
  "new_form.creation_language": "Creation Language",
  "languages.portuguese": "Portuguese",
  "languages.english": "English",
  "languages.spanish": "Spanish",
  "languages.german": "German",
  "languages.mandarin": "Mandarin",
  "languages.french": "French",
  "languages.turkish": "Turkish",
  "fields.text": "Short Text",
  "fields.textarea": "Long Text",
  "fields.number": "Numeric",
  "fields.datetime": "Date and Hour",
  "fields.photo": "Photo",
  "fields.dropdown": "Dropdown",
  "fields.single_option": "Single Option",
  "fields.customer": "Customer",
  "fields.equipment": "Equipment",
  "fields.campaign": "Campaign",
  "fields.end_reason": "Ending Reason",
  "fields.qr_code": "Qr/Bar Code",
  "fields.signature_qr": "Signature QR",
  "fields.boolean": "Single Option",
  "fields.dropdown-campaign": "Campaign",
  "fields.dropdown-campaign-end-reason": "Ending Reason",
  "fields.dropdown-customer": "Customer",
  "fields.dropdown-equipment": "Equipment",
  "fields.pictureFile": "Photo",
  "fields.qrbarcode": "Qr/Bar Code",
  "fields.signature-qrcode": "Signature QR",
  "fields.check_list": "Checklist",
  "constructor.fields": "Fields",
  "constructor.filter": "Filter",
  "constructor.by.equipment": "Filter by equipment",
  "constructor.by.customer": "Filter by customer",
  "constructor.basic": "Basic",
  "preferences.checklistValue": "Max checked options",
  "constructor.rhim": "RHIM Integration",
  "constructor.section_title": "Section title",
  "constructor.unsaves_changes": "Unsaved changes",
  "constructor.mrk": "Equipment MRK",
  "constructor.preferences": "Preferences",
  "preferences.placeholder": "Select a field to edit its preferences",
  "preferences.required": "Required",
  "preferences.score": "Score",
  "preferences.options": "Options",
  "preferences.automatic": "Automatic",
  "preferences.automatic_id": "Automatic Id",
  "preferences.field_id": "Field Id",
  "preferences.field_weight": "Field Weight",
  "preferences.section_weight": "Section Weight",
  "preferences.section_id": "Section Id",
  "preferences.options_placeholder": "Type items by separating them with enter",
  "preferences.mrk_placeholder": "Select a MRK",
  "preferences.max": "Maximum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Use as form description",
  "preferences.error_empty_qrcode": "Choose at least one option",
  "preferences.typeQrOrBarCode": "Type",
  "preferences.IntegerOrFloat": "Type",
  "preferences.decimalPlacesMin" : "Decimal Places Min",
  "preferences.DecimalPlaces": "DecimalPlaces",
  "add_options.option": "Option",
  "add_options.weight": "Weight",
  "add_options.response": "Response",
  "add_options.add_na": "Add N/A",
  "construction.go_back": "Go back",
  "languages.notes": "Notes",
  "users.email": "Users E-mail",
  "coOwners.email": "Co-owners E-mail",
  "users.multiple_association":
    "Multiple association (fill it with a mail list with semicolon separator)",
  "users.finish": "Finish form",

  "globalization.title": "Write your form title in",
  "globalization.section": "Write your section title in",
  "globalization.field": "Write your field name in",
  "globalization.field.description": "Write your field's description in",
  "globalization.option": "Write your option value in",

  "rules.if": "If",
  "rules.status": "Status",
  "rules.response": "Response",
  "rules.action": "Action",
  "rules.question": "Question",
  "rules.options": "Options",

  "option.is": "Is",
  "option.isn't": "Isn't",
  "option.hide.section": "Hide Section",
  "option.hide.field": "Hide Field",
  "option.show.section": "Show Section",
  "option.show.field": "Show Field",
  "option.filter": "Filter",
  "option.required": "Required",

  "pagination.view": "Viewing {{from}}-{{to}} from {{count}}",
  "pagination.rows_per_page": "Rows per page:",
  "messages.error_form_access_denied":
    "You do not have access to this form. Please look for a form where you are the owner or co-owner",
  "messages.page_not_found": "Page not found",
  "messages.text_page_not_found": "Sorry, the page you're trying to access doesn't exist.",
  "messages.go_back_my_forms": "Go back to My Forms",
  "messages.access_denied": "Access denied",
  "messages.text_access_denied":
    "You don't have access to this form. Please, search for another form in which you are an author or co-author.",
  "messages.form_not_found": "Form not found",
  "messages.text_form_not_found":
    "Sorry, the form you're trying to access doesn't exist.Please, search for another form or create a new one.",
  "preferences.description": "Description",
  "messages.fieldsInformDescriptionPreference":
    "You have already selected {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fields",
  "messages.error_single_option_no_options":
    "You cannot save a single option field without options.",
  "messages.max_filled_as_form_description": "To select this field, please unselect other field",

  "preferences.option_integer": "Integer",
  "preferences.option_decimal": "Decimal",
  "messages.tooltip_decimal_minimum_alert":
    "Due to the 2 decimal places, the minimum character limit for decimal type fields is 3.",
  "message.form_last_update": "Last update",
  "message.form_last_update_by": "By"
};
