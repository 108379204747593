import React from "react";
import "./UsersAssociationPage.scss";
import EmailAssociationInput from "./components/EmailAssociationInput";
import { useStoreState } from "../../../../store/hooks";

const UsersAssociationPage = () => {
  const form = useStoreState((state) => state.form.form!);
  const ownerEmail = form.ownerEmail;
  const userEmail = useStoreState((state) => state.auth.userData?.unique_name!);
  const isOwner = ownerEmail === userEmail;

  return (
    <section className="users-association">
      <EmailAssociationInput formEmailsType={"formUsers"} />
      {isOwner && <EmailAssociationInput formEmailsType={"formCoOwners"} />}
    </section>
  );
};

export default UsersAssociationPage;
